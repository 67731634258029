import React, { CSSProperties, useContext } from "react"

import loadable from "@loadable/component"
import cn from "classnames"
import contrast from "contrast"
import Image, { FluidObject } from "gatsby-image"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import { RichText } from "prismic-reactjs"

import {
  BalanceText,
  Col,
  Container,
  Heading,
  HrefButton,
  Row,
  Textarea,
} from "~/components/ui"
import { ThemeContext } from "~/context/ThemeContext"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import {
  convertImageDataToChefclubProxy,
  getPrismicLinkProps,
} from "~/utils/PrismicLink"

import css from "./TextImageColumnsV2.module.scss"

const RenderLottie = loadable(() => import("~/components/Lottie/Lottie"))

export interface TextImageColumnsV2Props {
  layout: "Image, text" | "Text, image"
  backgroundColor: CSSProperties["color"] | null
  backgroundIsFullWidth: boolean
  title: PrismicStructuredText
  text: PrismicStructuredText
  link: PrismicLink
  linkStyle: "Inline" | "Prominent (secondary)" | "Prominent"
  linkLabel: PrismicStructuredText
  secondLink: PrismicLink
  secondLinkLabel: PrismicStructuredText
  buttonColor: CSSProperties["color"] | null
  image: {
    alt: string | null
    fluid: FluidObject | null
    gatsbyImageData?: IGatsbyImageData
    thumbnails: {
      Square: {
        fluid: FluidObject | null
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  imageSize: "Main" | "Square"
  lottie: {
    url: null | string
  }
  anchorId?: string
}

interface PropTypes extends TextImageColumnsV2Props {
  isDarkTheme?: boolean
  isVisible?: boolean
  isLeadingSlice?: boolean
  eagerImages?: boolean
}

function getFontColor(color: string): "body" | "white" {
  return contrast(color) === "dark" ? "white" : "body"
}

function TextImageColumnsV2({
  title,
  text,
  linkLabel,
  layout,
  image,
  link,
  secondLink,
  secondLinkLabel,
  isDarkTheme,
  imageSize,
  linkStyle,
  backgroundColor,
  backgroundIsFullWidth,
  buttonColor,
  lottie,
  isVisible,
  isLeadingSlice,
  eagerImages,
}: PropTypes) {
  const theme = useContext(ThemeContext)
  const isKidsTheme = theme === "kids"
  const isFoodTheme = theme === "food"

  const reverseLayout = layout === "Text, image"
  const imageIsSquare = imageSize === "Square"

  const linkProps = getPrismicLinkProps(link)
  const hasLink = !!linkProps

  const hasButton = hasLink && !!linkLabel.text
  const buttonIsTertiary = linkStyle === "Inline"

  const secondLinkProps = getPrismicLinkProps(secondLink)
  const hasSecondButton = !!secondLinkProps && !!secondLinkLabel.text

  const hasLinkButton = (hasButton && !buttonIsTertiary) || hasSecondButton

  const textColor = backgroundColor
    ? isFoodTheme
      ? "white"
      : getFontColor(backgroundColor)
    : isDarkTheme
    ? "white"
    : "body"

  const imageFluid = convertImageDataToChefclubProxy(
    imageIsSquare
      ? image.thumbnails.Square.gatsbyImageData
      : image.gatsbyImageData
  )

  return (
    <div
      className={cn({
        [css.root]: true,
        [css.kidsTheme]: isKidsTheme,
        [css.hasBackground]: !!backgroundColor,
        [css.isInset]: !!backgroundColor && !backgroundIsFullWidth,
      })}
      style={
        {
          "--textimage-background-color": backgroundColor,
        } as CSSProperties
      }
    >
      <Container>
        <Row
          className={css.row}
          reverse={[false, reverseLayout]}
          align={["initial", "middle"]}
        >
          <Col width={[12, imageIsSquare ? 5 : 6]}>
            {lottie?.url ? (
              <RenderLottie url={lottie.url} play={isVisible} />
            ) : !!imageFluid ? (
              <a href={hasLink ? linkProps.href : undefined}>
                <GatsbyImage
                  className={cn(css.image, { [css.hover]: hasLink })}
                  image={imageFluid}
                  alt={image.alt || ""}
                  title={title.text}
                />
              </a>
            ) : null}
          </Col>
          <Col width={[12, imageIsSquare ? 7 : 6]}>
            {title?.text && (
              <BalanceText>
                {({ className }) => (
                  <Heading
                    variant="large"
                    className={cn([css.title, className])}
                    color={textColor}
                    Tag={isLeadingSlice ? "h1" : undefined}
                  >
                    <a href={hasLink ? linkProps.href : undefined}>
                      {title.text}
                    </a>
                  </Heading>
                )}
              </BalanceText>
            )}

            <Textarea className={css.textarea} color={textColor}>
              <RichText render={text.raw} />{" "}
              {hasButton && buttonIsTertiary && (
                <a
                  href={hasLink ? linkProps.href : undefined}
                  className={css.cta}
                  style={{ color: buttonColor || "inherit" }}
                >
                  {linkLabel.text}
                </a>
              )}
            </Textarea>
            {hasLinkButton && (
              <div className={css.buttonContainer}>
                {hasButton && !buttonIsTertiary && (
                  <HrefButton
                    className={css.button}
                    variant={
                      linkStyle === "Prominent" ? "primary" : "secondary"
                    }
                    color={
                      buttonColor || (!!backgroundColor ? "white" : "black")
                    }
                    href={hasLink ? linkProps.href : undefined}
                  >
                    {linkLabel.text}
                  </HrefButton>
                )}

                {hasSecondButton && (
                  <HrefButton
                    variant="tertiary"
                    color={buttonColor || textColor}
                    href={secondLink.url}
                  >
                    {secondLinkLabel.text}
                  </HrefButton>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TextImageColumnsV2
