import React from "react"

import cn from "classnames"
import Img, { FluidObject } from "gatsby-image"
import {
  GatsbyImage,
  IGatsbyImageData,
  withArtDirection,
} from "gatsby-plugin-image"

import { PrismicLink } from "~/models/PrismicTypes"
import { mobileScreenWidth } from "~/utils/general"
import {
  convertImageDataToChefclubProxy,
  getPrismicLinkProps,
} from "~/utils/PrismicLink"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./ImageBanner.module.scss"

export interface ImageBannerProps {
  link: PrismicLink
  bannerHeight: "Main" | "High" | "16:9"
  desktopImage: {
    fluid: FluidObject | null
    gatsbyImageData: IGatsbyImageData
    thumbnails: {
      High: null | {
        gatsbyImageData: IGatsbyImageData
        fluid: FluidObject | null
      }
      SixteenByNine: null | {
        gatsbyImageData: IGatsbyImageData
        fluid: FluidObject | null
      }
    }
  }
  mobileImage: {
    gatsbyImageData: IGatsbyImageData
    fluid: FluidObject | null
    thumbnails: {
      High: null | {
        gatsbyImageData: IGatsbyImageData
        fluid: FluidObject | null
      }
      SixteenByNine: null | {
        gatsbyImageData: IGatsbyImageData
        fluid: FluidObject | null
      }
    }
  }
  anchorId?: string
}

interface PropTypes extends ImageBannerProps {
  eagerImages?: boolean
}

function ImageBanner({
  link,
  bannerHeight,
  desktopImage,
  mobileImage,
  eagerImages,
}: PropTypes) {
  const linkAttr = getPrismicLinkProps(link)
  const imageClassNames = cn(css.image, {
    [css[`variant${bannerHeight}`]]: true,
  })

  let Image = null

  const mobileGatsbyImage =
    bannerHeight === "High"
      ? mobileImage.thumbnails.High?.gatsbyImageData
      : bannerHeight === "16:9"
      ? mobileImage.thumbnails.SixteenByNine?.gatsbyImageData
      : mobileImage.gatsbyImageData

  const desktopGatsbyImage =
    bannerHeight === "High"
      ? desktopImage.thumbnails.High?.gatsbyImageData
      : bannerHeight === "16:9"
      ? desktopImage.thumbnails.SixteenByNine?.gatsbyImageData
      : desktopImage.gatsbyImageData

  if (desktopImage.gatsbyImageData && mobileImage.gatsbyImageData) {
    const gatsbyImage = withArtDirection(
      desktopGatsbyImage ?? desktopImage.gatsbyImageData,
      [
        {
          media: `(max-width: ${mobileScreenWidth})`,
          image: mobileGatsbyImage ?? mobileImage.gatsbyImageData,
        },
      ]
    )
    Image = (
      <GatsbyImage
        className={imageClassNames}
        image={convertImageDataToChefclubProxy(gatsbyImage)}
        alt="banner"
        loading={eagerImages ? "eager" : undefined}
      />
    )
  }

  if (!Image) return null

  return (
    <a {...linkAttr} title="">
      {Image}
    </a>
  )
}

export default ImageBanner
