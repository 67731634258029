import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import Tag from "./Tag/Tag"
import { Body, Heading } from "~/components/ui"
import { LatestLanding } from "~/templates/Landing/Landing"
import { convertImageDataToChefclubProxy } from "~/utils/PrismicLink"

import css from "./LandingsFullWidthCard.module.scss"

export interface LatestLandingProps {
  landing: LatestLanding
  tag: string
  isDarkTheme?: boolean
  tagColor?: string
  eagerImages?: boolean
}

function LandingsFullWidthCard({
  landing,
  tag,
  isDarkTheme,
  tagColor,
  eagerImages,
}: LatestLandingProps) {
  return (
    <>
      {landing.data.seoImage.gatsbyImageData && (
        <div className={css.content}>
          <GatsbyImage
            loading={eagerImages ? "eager" : undefined}
            image={convertImageDataToChefclubProxy(
              landing.data.seoImage.gatsbyImageData
            )}
            alt={landing.data.seoImage.alt || ""}
            className={css.image}
          />
          <div className={css.overlay}>
            <div className={css.header}>
              {tag && <Tag tag={tag} tagColor={tagColor} />}
              <Heading
                variant="medium"
                className={css.title}
                color={isDarkTheme ? "white" : "body"}
              >
                {landing.data.metaTitle}
              </Heading>
            </div>
            <Body
              variant="body3"
              className={css.description}
              color={isDarkTheme ? "white" : "body"}
            >
              {landing.data.meta_description}
            </Body>
          </div>
        </div>
      )}
    </>
  )
}

export default LandingsFullWidthCard
