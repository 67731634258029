import React from "react"

import cn from "classnames"

import Link from "~/components/Link/Link"
import { Body, Heading } from "~/components/ui"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { replacePrismicImageURL } from "~/utils/PrismicLink"

import css from "./List.module.scss"

export interface ListProps {
  displayMax?: number
  seeMoreLink?: PrismicLink
  seeMoreLinkText?: string
  imageForm?: string
  title: PrismicStructuredText
  small?: boolean
  content: {
    illustration?: string
    text: string
    tag?: string
    link?: string
  }[]
  horizontalInMobile?: boolean
}

interface PropTypes extends ListProps {
  isPreview?: boolean
  isDarkTheme?: boolean
  headerIsNotHx?: boolean
}

function List({
  title,
  displayMax,
  seeMoreLink,
  seeMoreLinkText,
  imageForm = "square",
  small,
  content,
  horizontalInMobile,
  headerIsNotHx,
}: PropTypes) {
  const noImage = !content[0]?.illustration || imageForm === "icon"
  const centerVertically = imageForm === "round"
  const smallGap = imageForm === "round"

  return (
    <div className={css.container}>
      <Heading Tag={headerIsNotHx ? "p" : undefined} variant="small">
        {title.text}
      </Heading>
      <div
        className={cn(css.items, {
          [css.horizontalInMobile]: horizontalInMobile,
        })}
        style={noImage ? { gap: 0 } : {}}
      >
        {content.map((item, index) => {
          return (
            <Link
              to={item.link ?? ""}
              key={index}
              className={cn(css.item, {
                [css.horizontalInMobile]: horizontalInMobile,
                [css.smallGap]: smallGap,
              })}
              absoluteLink
            >
              {item.illustration && (
                <img
                  className={cn(
                    css.image,
                    {
                      [css.square]: imageForm === "square",
                      [css.round]: imageForm === "round",
                      [css.icon]: imageForm === "icon",
                    },
                    {
                      [css.small]: small,
                    }
                  )}
                  src={replacePrismicImageURL(item.illustration)}
                  alt={item.text}
                />
              )}
              <div className={css.textContent}>
                {item.tag && (
                  <div className={css.tag}>
                    <Body variant="body4" semiBold uppercase noMargin>
                      {item.tag}
                    </Body>
                  </div>
                )}
                <div
                  className={cn(css.text, {
                    [css.lastText]: index === content.length - 1,
                    [css.margin]: noImage,
                    [css.centerVertically]: centerVertically,
                  })}
                >
                  {noImage || small ? (
                    <Body variant="body2" semiBold noMargin>
                      {item.text}
                    </Body>
                  ) : (
                    <Heading
                      className={css.heading}
                      variant="extraSmall"
                      noMargin
                    >
                      {item.text}
                    </Heading>
                  )}
                </div>
              </div>
            </Link>
          )
        })}
      </div>
      {seeMoreLink?.url && (
        <Link
          to={seeMoreLink.url}
          absoluteLink
          className={cn(css.seeMoreLink, {
            [css.horizontalInMobile]: horizontalInMobile,
            [css.smallMarginInDesktop]: noImage,
          })}
        >
          <Body variant="body3" color="gray2" semiBold noMargin>
            {seeMoreLinkText}
          </Body>
        </Link>
      )}
    </div>
  )
}

export default List
